module.exports.constants = {
    keyCustomerToken: 'iuv0984ty98v4ts',
    keyUserData: 'lvsgmjp3oijq0434',
    keyMainData: 'mus09p3qopwab3c',
    keySelectedTemplate: 'arv12bv08sr26rs'
};

module.exports.characterLimits = {
    profile: {
        name: 30,
        heading: 30,
        subHeading: 30,
        iconsCharacter : 30,
    },
    headingText:{
        title:30,
        description: 500,
    },
    contactUs:{
        title :30,
        contactNumber: 20,
        email: 30
    },
    images:{
        title: 30,
        description: 150,
    },
    socialLinks:{
        title: 30,
        description: 150,
        socialLinkTitle: 30,
        socialLinkSubTitle: 100,
    },
    links:{
        title: 30,
        description: 150,
        linkTitle: 30,
        linkSubTitle: 100,
    },
    video:{
        title: 30,
        description: 150,
    },
    otherDetails: {
        title: 30,
        description: 150,
        otherLable: 30,
        otherTextDescription: 500,
    },
    enquiry:{
        title: 30,
    },
};
