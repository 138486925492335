import { useState, useCallback, useEffect } from 'react';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useRouter } from 'src/routes/hook';
import { decryptToken } from 'src/utils/common';
import { constants } from 'src/utils/constant';
import axios, { endpoints } from 'src/utils/axios';
import { useBoolean } from 'src/hooks/use-boolean';
import { TableHeadCustom, TablePaginationCustom, TableNoData } from 'src/components/table';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import { filter } from 'lodash';
import OrderTableRow from '../order-table-row'; // Move this import down
import OrderTableToolbar from '../order-table-toolbar'; // Move this import down

const TABLE_HEAD = [
  { id: 'orderNumber', label: 'FirstName', width: 60, align: 'center' },
  { id: 'name', label: 'LastName', width: 60, align: 'center' },
  { id: 'status', label: 'Message', width: 200, align: 'center' },
  { id: 'totalAmount', label: 'Phone', width: 80, align: 'center' },
  { id: 'totalQuantity', label: 'Email', width: 80, align: 'center' },
  { id: 'createdAt', label: 'Date', width: 80, align: 'center' },
];

const defaultFilters = {
  name: '',
  startDate: null,
  endDate: null,
};

export default function EnquiryView() {
  const settings = useSettingsContext();
  const router = useRouter();
  const confirm = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [loading ,setLoading]=useState(false)

  const token = decryptToken(localStorage.getItem(constants.keyCustomerToken));

  useEffect(() => {
    const getAllEnquiries = async () => {
      try {
        const response = await axios.get(endpoints.user.getEnquiries, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: page + 1,
            limit: rowsPerPage,
            name: filters.name,
            startDate: filters.startDate ? filters.startDate.toISOString() : '',
            endDate: filters.endDate ? filters.endDate.toISOString() : '',
          },
        });

        const { enquiries, totalPages } = response.data.data;

        //  setTotalEnquiries(totalPages * rowsPerPage);
        setTotalEnquiries(response.data.data.totalEnquiries);
        setTableData(enquiries);
        if (!enquiries) {
          setLoading(true)
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }

    };

    getAllEnquiries();
  }, [token, page, rowsPerPage, filters]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const filteredData = applyFilter({
    inputData: tableData,
    comparator: getComparator('timestamp', 'desc'),
    filters,
    dateError,
  });


  const dataInPage = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const denseHeight = 72; // Adjust based on density

  const canReset = !!filters.name || (!!filters.startDate && !!filters.endDate);

  const notFound = (!filteredData.length && canReset) || !filteredData.length;

  const handleFilters = useCallback((name, value) => {
    setPage(0); // Reset to first page on filter change
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);
    },
    [tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !row.selected.includes(row.id));
    setTableData(deleteRows);
  }, [tableData]);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.order.details(id));
    },
    [router]
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
          <Card>
            <OrderTableToolbar
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order="desc" // Adjust as necessary
                    orderBy="timestamp" // Adjust as necessary
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    onSort={() => {}} // Add sorting if needed
                  />

                  <TableBody>
                    {tableData.map((row) => (
                      <OrderTableRow
                        key={row.id}
                        row={row}
                        onViewRow={() => handleViewRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={totalEnquiries}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete All Selected"
          content="Are you sure you want to delete all selected rows?"
          onConfirm={handleDeleteRows}
        />
      </LocalizationProvider>
    </>
  );
}

// Utility Functions

const applyFilter = ({ inputData, comparator, filters, dateError }) => {
  const { name, startDate, endDate } = filters;

  let filteredData = inputData;

  if (name) {
    filteredData = filteredData.filter(
      (item) =>
        item.firstName.toLowerCase().includes(name.toLowerCase()) ||
        item.lastName.toLowerCase().includes(name.toLowerCase())
    );
  }

  if (startDate && endDate && !dateError) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    filteredData = filteredData.filter((item) => {
      const date = new Date(item.timestamp);
      return date >= start && date <= end;
    });
  }

  return filteredData.sort(comparator);
};

const getComparator = (orderBy, order) => (a, b) => {
  if (b[orderBy] < a[orderBy]) return order === 'desc' ? -1 : 1;
  if (b[orderBy] > a[orderBy]) return order === 'desc' ? 1 : -1;
  return 0;
};

//   const { name, startDate, endDate } = filters;

//   let filteredData = inputData;

//   // Filter by Name (Search)
//   if (name) {
//     filteredData = filteredData.filter(
//       (item) =>
//         item.firstName.toLowerCase().includes(name.toLowerCase()) ||
//         item.lastName.toLowerCase().includes(name.toLowerCase())
//     );
//   }

//   // Filter by Date Range (if both startDate and endDate are provided, and no dateError)
//   if (startDate && endDate && !dateError) {
//     const start = new Date(startDate);
//     const end = new Date(endDate);

//     filteredData = filteredData.filter((item) => {
//       const date = new Date(item.timestamp); // Ensure item.timestamp is a date
//       return date >= start && date <= end;
//     });
//   }

//   // Sort the filtered data using the comparator
//   return filteredData.sort(comparator);
// };

