
import { lazy, Suspense } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import AuthClassicLayout from 'src/layouts/auth/classic';
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { paths } from '../paths';

// layouts
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

const JwtForgetPage = lazy(() => import('src/pages/auth/jwt/forgetpassword'));


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/customer',
      element: <Navigate to={paths.auth.jwt.login} replace />,
    },

    // Auth routes
    // ...authRoutes,
    {
      path: '/customer/login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: '/customer/register',
      element: (
        <AuthClassicLayout title="Sign Up">
          <JwtRegisterPage />
        </AuthClassicLayout>
      ),
    },

    {
      path: '/customer/forgetpassword',
      element: (
        <AuthClassicLayout title='Reset Password'>
          <JwtForgetPage />
        </AuthClassicLayout>
      ),
    },

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '/customer/*', element: <Navigate to="/404" replace /> },
  ]);
}
