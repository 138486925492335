




import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { fCurrency } from 'src/utils/format-number';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// ----------------------------------------------------------------------

export default function OrderTableRow({ row, selected, onViewRow, onSelectRow, onDeleteRow }) {
  const { firstName, lastName, message, phone, email, timestamp } = row;

  const confirm = useBoolean();
  const collapse = useBoolean();
  const popover = usePopover();
  const [openMessageDialog, setOpenMessageDialog] = useState(false);

  const handleOpenMessageDialog = () => {
    setOpenMessageDialog(true); // Set to true to open the dialog
    popover.onClose();
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false); // Set to false to close the dialog
  };

  const renderPrimary = (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox"> */}
      {/* <Checkbox checked={selected} onClick={onSelectRow} /> */}
      {/* </TableCell> */}

      <TableCell align="center">
        {/* <ListItemText
          primary={firstName}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
        /> */}
        {firstName}
      </TableCell>

      <TableCell align="center">{lastName}</TableCell>
      {/* <TableCell sx={{ width: 340 }} align="center">{message}</TableCell> */}
      {/* <TableCell
        sx={{
          width: 180,
          maxWidth: 180, // Limit the maximum width
          wordWrap: 'break-word', // Ensure long words break and wrap
          whiteSpace: 'normal', // Allow wrapping to multiple lines
          overflow: 'hidden', // Hide overflow if needed
          textOverflow: 'ellipsis', // Add ellipsis if necessary for overflow
        }}
        align="center"
      >
        {message}
      </TableCell> */}
      <TableCell
        sx={{
          width: 180,
          maxWidth: 180,
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        align="center"
      >
        {message.length > 50 ? (
          <>
            {message.slice(0, 50)}...{' '}
            <Button onClick={handleOpenMessageDialog} size="small" color="primary">
              Read More
            </Button>
          </>
        ) : (
          message
        )}
      </TableCell>
      <TableCell align="center">{phone}</TableCell>
      <TableCell align="center">{email}</TableCell>
      <TableCell align="center">
        <ListItemText
          primary={
            isValid(new Date(timestamp))
              ? format(new Date(timestamp), 'dd MMM yyyy')
              : 'Invalid Date'
          }
          secondary={isValid(new Date(timestamp)) ? format(new Date(timestamp), 'p') : ''}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
    </TableRow>
  );

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
          {/* Content for secondary row */}
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}
      {renderSecondary}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
        <DialogTitle>Message</DialogTitle>
        <DialogContent>
          <Box sx={{ whiteSpace: 'pre-line' }}>{message}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

OrderTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
};




