// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';
import { decryptToken, encryptToken } from 'src/utils/common';
import { constants } from 'src/utils/constant';
// import { useSearchParams, useRouter } from 'src/routes/hook';

// ----------------------------------------------------------------------

export const jwtDecode = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');
    localStorage.clear();

    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {

    const encryptedToken = encryptToken(accessToken);
    sessionStorage.setItem('accessToken', encryptedToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  } else {
    sessionStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export function redirectToLoginPage() {
  // Display a message
  alert("Your session has expired. Please log in again.");
  // Redirect to '/login';
  window.location.href = '/customer/login';

}

export function clearToken() {
  localStorage.clear();
  sessionStorage.clear();
}


export const restoreTokenFromStorage = () => {
  const token = localStorage.getItem(constants.keyCustomerToken);
  if (!token) {
    // Token is not present, redirect to login page
    redirectToLoginPage();
    return;
  }

  const accessToken = decryptToken(token);
  if (!accessToken) {
    // Token decryption failed, redirect to login page
    redirectToLoginPage();
    return;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTimestamp = Math.floor(Date.now() / 1000);

  if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
    console.log("token Expired")
    // Token is expired, clear token and redirect to login page
    clearToken();
    redirectToLoginPage();
    return;
  }

  // Token is valid, set authorization header
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};