import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useRouter } from 'src/routes/hook';

function PopupBox({ message, setShowPopup }) {
  const router = useRouter();
  const popupRef = useRef()

  const handleConfirm = () => {
    setShowPopup(false);
    router.replace('/customer/login'); // Redirect to login page
  };

  const handleCancel = () => {
    setShowPopup(false); // Close the popup without redirecting
  };

     // Close modal if clicked outside
     useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false); // Close the modal
            }
        };

        // Attach event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[setShowPopup]);


  return (
    <div className="fixed inset-0 top-0 right-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-50 md:bg-[#9c9c9c] md:bg-opacity-50 ">
      <div ref={popupRef} className="bg-white rounded-lg p-6 w-fit max-w-3/4 text-center shadow-2xl">
        <p className="text-lg font-medium mb-4">{message}</p>
        <div className="flex justify-center space-x-4 gap-x-5">
          <button
            type="button"
            onClick={handleConfirm}
            className="px-4 py-2 bg-[#04a56f] text-white rounded hover:bg-blue-600 transition"
          >
            Yes
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

PopupBox.propTypes = {
  message: PropTypes.string,
  setShowPopup: PropTypes.func.isRequired,
};

PopupBox.defaultProps = {
  message: 'You need to log in to access this page.',
};

export default PopupBox;
