// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/customer/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: '/customer/login',
      register: `/customer/register`,
      forgetpassword: '/customer/forgetpassword',
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    one: `${ROOTS.DASHBOARD}/products`,
    two: `${ROOTS.DASHBOARD}/categories`,
    three: `${ROOTS.DASHBOARD}/templates`,
    profile: `${ROOTS.DASHBOARD}/profile`,
    enquire: `${ROOTS.DASHBOARD}/enquire`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
      five: `${ROOTS.DASHBOARD}/group/five`,
      six: `${ROOTS.DASHBOARD}/group/six`,
    },
  },
};
