
import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ENCRYPT_TOKEN_SECRET_KEY;
const iv = CryptoJS.enc.Hex.parse('eSFcfwuau54W6');

// Encrypt function
export const encryptToken = (data) => {
    const encrypted = CryptoJS.AES.encrypt(data, secretKey, { iv });
    return encrypted.toString();
};
// console.log('encryptToken===>', encryptToken);

// Decrypt function
export const decryptToken = (encryptedData) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, secretKey, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
};
// console.log('decryptToken----------->', decryptToken);




export const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format the date part
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

    // Format the day of the week
    const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);

    // Format the time
    const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    return `${formattedDate}, ${dayOfWeek}, ${time}`;
};