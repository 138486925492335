import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/user/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/product/list',
    add: '/product/add',
    update: '/product/update',
    delete: '/product/delete',
  },
  category: {
    list: '/category/list',
    add: '/category/add',
    update: '/category/update',
    delete: '/category/delete',
  },
  user: {
    miniWebSiteHistory: '/user/update-mini-history',
    updateProfile: '/user/updateProfile',
    uploadProfileImage: '/user/uploadProfileImage',
    userGetById: '/user/userGetById',
    updateStatus: '/user/updateStatus',
    uploadImages: '/user/uploadImages',
    addEnquiry: '/user/addEnquiry',
    checkUsername: '/user/check-username',
    getEnquiries: '/user/getEnquiries',
    getDashboardData: '/user/getDashboardData',
    deviceDetails: '/user/deviceDetails', 
    kycVerification: '/user/kycVerification', 
    activeTemplate: '/user/activeTemplate',
    templateData: "/user/template-data",
  },
};
