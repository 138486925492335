import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import EnquiryView from 'src/sections/enquire/view/view';

// ----------------------------------------------------------------------

const ProductsPage = lazy(() => import('src/pages/dashboard/one'));
// const PageTwo = lazy(() => import('src/pages/dashboard/two'));
const Templates = lazy(() => import('src/pages/dashboard/three'));
const ProfilePage = lazy(() => import('src/pages/dashboard/profile'));
// const PageFive = lazy(() => import('src/pages/dashboard/five'));
const Dashboard = lazy(() => import('src/pages/dashboard/six'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/customer/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Dashboard />, index: true },
      { path: 'products', element: <ProductsPage /> },
      { path: 'profile', element: <ProfilePage /> },
      // { path: 'templates', element: <Templates /> },
      { path: 'enquire', element: <EnquiryView /> },
      // {
      //   path: 'group',
      //   children: [
      //     { element: <PageFour />, index: true },
      //     { path: 'five', element: <PageFive /> },
      //     // { path: 'six', element: <PageSix /> },
      //   ],
      // },
    ],
  },
   // Route for 'templates' without AuthGuard
   {
    path: '/customer/dashboard/templates',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Templates />
        </Suspense>
      </DashboardLayout>
    ),
  },
];
